<template>
  <div class="global-leaderboard">
    <div class="top-header">
      <div class="container">
        <div class="title">
          <h2>Last week's top performers</h2>
        </div>
        <el-row :gutter="30">
          <el-col
            :xl="12"
            :md="12"
            :sm="24"
            :xs="24"
          >
            <div class="card">
              <h3>Most liked award</h3>
              <div class="user-block">
                <el-col
                  :xl="4"
                  :md="4"
                  :sm="24"
                >
                  <vsvg-icon
                    data="@assetsIcon/shirt-icon.svg"
                    scale="1"
                    original
                  />
                </el-col>
                <el-col
                  :xl="19"
                  :md="19"
                  :sm="24"
                >
                  <span v-if="!awardsIsLoading">{{ mostLikedAward }}</span>
                  <transition name="fade">
                    <div
                      v-loading="awardsIsLoading"
                      class="white-loader"
                    ></div>
                  </transition>
                </el-col>
              </div>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :md="12"
            :sm="24"
            :xs="24"
          >
            <div class="card">
              <h3>Points award</h3>
              <div class="user-block">
                <el-col
                  :xl="4"
                  :md="4"
                  :sm="24"
                >
                  <vsvg-icon
                    data="@assetsIcon/shirt-icon.svg"
                    scale="1"
                    original
                  />
                </el-col>
                <el-col
                  :xl="19"
                  :md="19"
                  :sm="24"
                >
                  <span v-if="!awardsIsLoading">{{ mostPointsAward }}</span>
                  <transition name="fade">
                    <div
                      v-loading="awardsIsLoading"
                      class="white-loader"
                    ></div>
                  </transition>
                </el-col>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="leaderboard-table">
      <div class="container">
        <h2 v-if="showLeaderboard">
          Leaderboard
        </h2>
        <div
          v-if="showLeaderboard"
          class="sort"
        >
          <span class="txt">Sort by</span>
          <div class="drop-sel">
            <el-select
              v-model="meta.sort_by"
              v-loading="sortIsLoading"
              placeholder="Select"
              element-loading-spinner="el-icon-loading"
              @change="handleSortChange($event); sortIsLoading = true"
            >
              <el-option
                v-for="(item, key) in sortOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="table-content">
          <div
            v-if="showLeaderboard"
            id="leaderboard-table"
          >
            <table>
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Members</th>
                  <th>Avg. pts per member</th>
                  <th>Avg. miles logged</th>
                  <th>Avg. activities logged</th>
                </tr>
              </thead>
              <transition name="fade">
                <div
                  v-if="!rows.length"
                  v-loading="!rows.length"
                  class="table-loader"
                  style="min-height: 90px"
                ></div>
              </transition>
              <tbody v-if="rows">
                <tr
                  v-for="(row, index) of rows"
                  :key="index"
                >
                  <td>
                    <button
                      class="team-information"
                      @click="fetchIndividualLeaderboard(row.team_id, row.team)"
                    >
                      {{ row.team }}
                    </button>
                  </td>
                  <td>{{ row.members }}</td>
                  <td>{{ row.avg_points }}</td>
                  <td>{{ row.avg_miles }}</td>
                  <td>{{ row.avg_activities }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="team && showIndividualLeaderboard"
            id="team-breakdown-table"
          >
            <template v-if="showIndividualLeaderboard">
              <h2>
                <button
                  class="team-information"
                  @click="backToMainLeaderboard()"
                >
                  LEADERBOARD
                </button> / {{ teamName }}
              </h2>
            </template>
            <transition name="fade">
              <div
                v-if="!team.length"
                v-loading="teamLoading"
                class="table-loader"
                style="min-height: 90px"
              ></div>
            </transition>
            <table>
              <thead>
                <tr>
                  <th>NAME</th>
                  <th>POINTS</th>
                  <th>MILES</th>
                  <th>ACTIVITIES</th>
                </tr>
              </thead>
              <tbody v-if="team">
                <tr
                  v-for="(i, index) of team"
                  :key="index"
                >
                  <td>{{ i.user }}</td>
                  <td>{{ i.points }}</td>
                  <td>{{ i.miles }}</td>
                  <td>{{ i.activities }}</td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="team.length === 0"
              class="no-data"
            >
              <h3>No data to show</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="leaderboard-table-pagination">
          <el-pagination
            v-if="pagination"
            :current-page.sync="pagination.current_page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="20"
            layout="total, prev, pager, next, jumper"
            :total="pagination.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import leaderboardApi from '@/modules/leaderboard/services/leaderboard.api'

export default {
  data: () => ({
    rows: [],
    team: [],
    teamName: '',
    awards: null,
    meta: {
      current_page: 1,
      per_page: 20,
      sort_by: 'avg_miles',
      sort: 'desc'
    },
    sortOptions: [
      { value: 'team', label: 'Team' },
      { value: 'members', label: 'Members' },
      { value: 'avg_points', label: 'Avg. pts per member' },
      { value: 'avg_miles', label: 'Avg. miles logged' },
      { value: 'avg_activities', label: 'Avg. activities logged' }
    ],
    pagination: null,
    sortIsLoading: false,
    awardsIsLoading: false,
    teamLoading: false,
    showLeaderboard: true,
    showIndividualLeaderboard: false,
  }),

  computed: {
    mostLikedAward() {
      return this.awards && this.awards.most_liked_award ? this.awards.most_liked_award.full_name : 'Not awarded last week'
    },
    mostPointsAward() {
      return this.awards && this.awards.most_points_award ? this.awards.most_points_award.full_name : 'Not awarded last week'
    }
  },

  created() {
    this.fetchAwards()
    this.fetchLeaderboard()
  },

  methods: {
    fetchAwards() {
      this.awardsIsLoading = true

      leaderboardApi
        .globalTeamAwards()
        .then((response) => {
          this.awardsIsLoading = false
          this.awards = response.data
        })
    },
    fetchLeaderboard(customParams) {
      this.sortIsLoading = true
      this.showLeaderboard = true
      leaderboardApi
        .globalTeamLeaderboard(
          _.assign({
            page: this.meta.current_page,
            per_page: this.meta.per_page,
            sort_by: this.meta.sort_by,
            sort: this.meta.sort_by == "team" ? 'asc' : 'desc'
          }, customParams)
        )
        .then((response) => {
          this.rows = response.data.data
          this.pagination = response.data.meta.pagination
          this.sortIsLoading = false
        })
    },
    fetchIndividualLeaderboard(teamId, name) {
      this.teamLoading = true
      this.showLeaderboard = false
      this.showIndividualLeaderboard = true

      leaderboardApi
        .globalIndividualLeaderboard({
          page: this.meta.current_page,
          per_page: this.meta.per_page,
          team_id: teamId
        })
        .then((response) => {
          this.team = response.data.data
          this.teamLoading = false
          this.teamName = name
        })
    },
    handleSortChange(event) {
      this.fetchLeaderboard({ sort_by: event });
    },
    handleSizeChange(event) {
      this.fetchLeaderboard({ page: 1, per_page: event });
    },
    handleCurrentChange(event) {
      this.fetchLeaderboard({ page: event });
    },
    backToMainLeaderboard(){
      this.fetchLeaderboard({ page: 1, per_page: this.meta.per_page })
      this.team = []
      this.teamName = ""
      this.showIndividualLeaderboard = false
    },
  }
}
</script>
